<template>
  <div class="">
    <form autocomplete="off">
      <div class="custom-tab-card">
        <div class="row">
          <div
            class="imgView"
            :style="{ 'background-image': `url(${imageSrc})` }"
            :onerror="`this.src='${employeeFolderData.defaultImg}'`"
            alt="Image"
            @click="selectImage"
          ></div>
          <!-- @input="pickFile" -->
          <input
            ref="fileInput"
            type="file"
            v-on:change="pickFile"
            class="d-none"
            accept=".jpg, .jpeg, .png"
          />
        </div>
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'employeeToken'"
            :value="employeeFolderData.employeeToken"
            :options="employeeTokenOptions"
            v-on:changeValue="employeeFolderData.employeeToken = $event"
            :title="$t('selectEmployee')"
            :imgName="'employees.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'employeeFolderNameAr'"
            :value="employeeFolderData.employeeFolderNameAr"
            :title="$t('employeeFolders.nameAr')"
            :imgName="'employeeFolders.svg'"
            v-on:changeValue="employeeFolderData.employeeFolderNameAr = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'employeeFolderNameEn'"
            :value="employeeFolderData.employeeFolderNameEn"
            :title="$t('employeeFolders.nameEn')"
            :imgName="'employeeFolders.svg'"
            v-on:changeValue="employeeFolderData.employeeFolderNameEn = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'employeeFolderNameUnd'"
            :value="employeeFolderData.employeeFolderNameUnd"
            :title="$t('employeeFolders.nameUnd')"
            :imgName="'employeeFolders.svg'"
            v-on:changeValue="employeeFolderData.employeeFolderNameUnd = $event"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'employeeFolderDescriptionAr'"
            :value="employeeFolderData.employeeFolderDescriptionAr"
            :title="$t('employeeFolders.descriptionAr')"
            :imgName="'description.svg'"
            v-on:changeValue="
              employeeFolderData.employeeFolderDescriptionAr = $event
            "
          />
          <TextArea
            :className="'col-md-6'"
            :id="'employeeFolderDescriptionEn'"
            :value="employeeFolderData.employeeFolderDescriptionEn"
            :title="$t('employeeFolders.descriptionEn')"
            :imgName="'description.svg'"
            v-on:changeValue="
              employeeFolderData.employeeFolderDescriptionEn = $event
            "
          />
          <TextArea
            :className="'col-md-6'"
            :id="'employeeFolderDescriptionUnd'"
            :value="employeeFolderData.employeeFolderDescriptionUnd"
            :title="$t('employeeFolders.descriptionUnd')"
            :imgName="'description.svg'"
            v-on:changeValue="
              employeeFolderData.employeeFolderDescriptionUnd = $event
            "
          />
          <TextArea
            :className="'col-md-12'"
            :id="'employeeFolderNotes'"
            :value="employeeFolderData.employeeFolderNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="employeeFolderData.employeeFolderNotes = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateEmployeeFolder"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="{
            name: 'EmployeeFolders',
          }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import { STATUS } from "@/utils/constants";
import axios from "axios";

export default {
  name: "EmployeeFolderForm",
  components: {
    CustomInput,
    TextArea,
    CustomSelectBox,
  },
  data() {
    return {
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeeTokenOptions: [],
    };
  },
  props: ["employeeFolderData", "imageSrc", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.employeeFolderData.imagePath = file[0];
      } else {
        this.imageSrc = this.employeeFolderData.defaultImg;
        this.employeeFolderData.imagePath = "";
      }
      // console.log(this.imageSrc);
    },
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      this.employeeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployee"),
      });
      try {
        const response = await axios.get(
          `/Employees/GetDialogOfEmployees?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async addOrUpdateEmployeeFolder() {
      this.$emit("addOrUpdateEmployeeFolder");
    },
  },
  async created() {
    this.getDialogOfEmployees();
  },
};
</script>

<style scoped lang="scss"></style>
