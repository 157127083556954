<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <EmployeeFolderForm
      v-if="!isLoading"
      :employeeFolderData="employeeFolder"
      :imageSrc="imageSrc"
      :submitName="$t('edit')"
      v-on:addOrUpdateEmployeeFolder="updateEmployeeFolder()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS, BASE_URL } from "@/utils/constants";
import EmployeeFolderForm from "@/components/employees/employeeFolders/EmployeeFolderForm.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import EmployeeFolder from "@/models/employees/employeeFolders/EmployeeFolder";

export default {
  name: "EmployeeFolderEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeFolderForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeFolder: new EmployeeFolder(),
      employeeToken: localStorage.getItem("employeeToken"),
    };
  },
  props: ["employeeFolderToken"],
  computed: {
    imageSrc: function () {
      if (this.employeeFolder.employeeFolderImagePath) {
        return BASE_URL + this.employeeFolder.employeeFolderImagePath;
      }
      return this.employeeFolder.defaultImg;
    },
  },
  methods: {
    async getEmployeeFolderDetails() {
      this.isLoading = true;
      this.employeeFolder.employeeFolderToken = this.employeeFolderToken;
      try {
        let response = await this.employeeFolder.getEmployeeFolderDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.employeeFolder.fillData(response.data.employeeFolderData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "EmployeeFolders" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "EmployeeFolders" }).catch(() => {});
      }
      this.isLoading = false;
    },
    async updateEmployeeFolder() {
      this.isLoading = true;

      try {
        let response = await this.employeeFolder.addOrUpdateEmployeeFolder(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "EmployeeFolders" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getEmployeeFolderDetails();
  },
};
</script>

<style scoped lang="scss"></style>
